const SCRIPT_ID = '--google-api';

export class GoogleAPI {
  private static hasLoaded: boolean = false;
  private config: Partial<IdConfiguration> = null;
  private client: Google['accounts']['id'] = null;
  private disableOneTap: boolean = false;
  private cbResolve: (value: string | PromiseLike<string>) => void;

  constructor(opts: Pick<IdConfiguration, 'client_id'>) {
    this.config = opts;
  }

  private loadClient() {
    return new Promise<void>((resolve) => {
      if (GoogleAPI.hasLoaded && this.client) {
        resolve();
      }
      else {
        const loadCB = () => {
          this.client = window.google.accounts.id;
          this.initClient();
          GoogleAPI.hasLoaded = true;
          resolve();
        };

        const maybeScriptElt = document.getElementById(SCRIPT_ID) as HTMLScriptElement;

        if (maybeScriptElt) {
          maybeScriptElt.onload = loadCB;
        }

        else {
          const scriptElt = document.createElement('script');
          scriptElt.src = 'https://accounts.google.com/gsi/client?hl=he_IL';
          scriptElt.id = SCRIPT_ID;
          scriptElt.onload = loadCB;

          const firstJS = document.getElementsByTagName('script')[0];
          firstJS.parentNode.insertBefore(scriptElt, firstJS);
        }
      }
    });
  }

  private initClient() {
    this.client.initialize({
      client_id: this.config.client_id,
      cancel_on_tap_outside: true,
      ux_mode: 'popup',
      auto_select: false,
      callback: (response) => {
        this.cbResolve(response && response.credential);
      },
    });
  }

  public async buttonLogin() {
    return new Promise<string>((resolve) => {
      this.cbResolve = resolve;
    });
  }

  public cancelPrompt() {
    this.client.cancel();
  }

  public async reactivateOneTapLogin() {
    this.disableOneTap = false;
    return this.oneTapLogin();
  }

  public async oneTapLogin() {
    if (this.disableOneTap) return null;
    await this.loadClient();
    return await (new Promise<string | null>((resolve) => {
      this.cbResolve = resolve;
      this.client.prompt((notificationMoment: PromptMomentNotification) => {
        this.disableOneTap = true;
        if (notificationMoment.isSkippedMoment()) {
          resolve(null);
        }
      });
    }));
  }
}
